import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import Button from "react-bootstrap/Button";
import TreatmentHero from "../../components/treatment-hero";
import ReviewSection from "../../components/review-section";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Faq from "../../components/faq";
import { checkPropertiesForNull } from "../../utils/objectHelper";
import { SafeHtmlParser } from "../../components/safe-html-parser";
import uuid4 from "uuid4";

const TeethWhiteningPage = () => {
	const data = useStaticQuery(graphql`
		query {
			reviews {
				reviewCount
			}
			schemaLogo: wpMediaItem(title: { eq: "Logo-footer" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "teeth-whitening" }) {
				slug
				seoFieldGroups {
					metaTitle
					metaDescription
					localBusinessSchema
					openGraphDescription
					openGraphTitle
					productSchema
					image {
						node {
							altText
							publicUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
				}
				teethWhiteningFieldGroups {
					teethWhiteningIntroSection {
						heading
						subHeading
						title
						subtitle
						description
						cta {
							title
							target
							url
						}
						image {
							node {
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
										original {
											width
											height
										}
									}
								}
							}
						}
					}
					teethWhiteningReviewSection {
						heading
						subHeading
					}
					teethWhiteningTreatmentSection {
						heading
						subHeading
						cta {
							title
							target
							url
						}
						items {
							heading
							content
							image {
								node {
									altText
									localFile {
										childImageSharp {
											gatsbyImageData(
												formats: [AUTO, WEBP]
												quality: 100
												transformOptions: { cropFocus: CENTER, fit: CONTAIN }
												layout: CONSTRAINED
												placeholder: BLURRED
											)
											original {
												width
												height
											}
										}
									}
								}
							}
						}
					}
					teethWhiteningTextSection {
						heading
						content
					}
					teethWhiteningFaqSection {
						heading
						faqs {
							nodes {
								... on WpFaq {
									faqFields {
										question
										answer
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const {
		pageData: { seoFieldGroups, teethWhiteningFieldGroups, slug },
	} = data;

	const {
		teethWhiteningIntroSection,
		teethWhiteningReviewSection,
		teethWhiteningTreatmentSection,
		teethWhiteningTextSection,
		teethWhiteningFaqSection,
	} = teethWhiteningFieldGroups;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const serviceSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"@id": `https://www.dentalhousepinner.co.uk/treatments/${slug}/#${slug}`,
		image: `https://www.dentalhousepinner.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
		mpn: `https://www.dentalhousepinner.co.uk/treatments/${slug}`,
		sku: `https://www.dentalhousepinner.co.uk/treatments/${slug}`,
		description: `${seoFieldGroups?.productSchema}`,
		logo: `https://www.dentalhousepinner.co.uk${data.schemaLogo?.localFile.publicURL}`,
		name: "Dental House Pinner",
		url: "https://www.dentalhousepinner.co.uk",
		brand: {
			"@type": "Organization",
			logo: `https://www.dentalhousepinner.co.uk${data.schemaLogo?.localFile.publicURL}`,
			image: `https://www.dentalhousepinner.co.uk${seoFieldGroups?.image?.node.localFile.publicURL}`,
			name: "Dental House Pinner",
			url: "https://www.dentalhousepinner.co.uk",
		},
		aggregateRating: {
			"@type": "AggregateRating",
			reviewCount: `${data.reviews.reviewCount}`,
			ratingValue: "4.9",
			bestRating: "5",
			worstRating: "1",
		},
		review: {
			"@type": "Review",
			url: "https://www.dentalhousepinner.co.uk",
			datePublished: "2023-08-23",
			reviewBody:
				"As someone terrified of dentists, it's been a pleasure being a patient of Dental House. Dr Shah's been a godsend, a truly welcoming presence who never fails to put you at ease. Clearly explains each procedure and how it should feel. The whole team are great!",
			author: {
				"@type": "Person",
				name: "N R",
			},
			reviewRating: {
				"@type": "Rating",
				ratingValue: "5",
				bestRating: "5",
				worstRating: "1",
			},
			publisher: {
				"@type": "Organization",
				name: "Dental House Pinner",
				sameAs: "https://www.dentalhousepinner.co.uk",
			},
		},
	};

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Treatments",
				item: {
					url: `${siteUrl}/treatments`,
					id: `${siteUrl}/treatments`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Teeth Whitening",
				item: {
					url: `${siteUrl}/treatments/teeth-whitening`,
					id: `${siteUrl}/treatments/teeth-whitening`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				<script type="application/ld+json">
					{JSON.stringify(serviceSchema)}
				</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/treatments/teeth-whitening`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.node.altText}`,
						},
					],
				}}
			/>

			<Layout>
				{teethWhiteningIntroSection &&
					!checkPropertiesForNull(teethWhiteningIntroSection, ["heading"]) && (
						<TreatmentHero
							heading1={teethWhiteningIntroSection?.title}
							smallHeadingTopText={teethWhiteningIntroSection?.subtitle}
							img={
								teethWhiteningIntroSection?.image.node?.localFile
									.childImageSharp.gatsbyImageData
							}
							imgAlt={teethWhiteningIntroSection?.image.node?.altText}
							heading2={teethWhiteningIntroSection?.heading}
							headingSmall={teethWhiteningIntroSection?.subHeading}
							text={
								<SafeHtmlParser
									htmlContent={teethWhiteningIntroSection?.description}
								/>
							}
							btnLink={teethWhiteningIntroSection?.cta.url}
							btnText={teethWhiteningIntroSection?.cta?.title}
						/>
					)}

				<ReviewSection
					heading={
						teethWhiteningReviewSection?.heading ||
						"Reviews From Our Dentistry Patients"
					}
					smallheading={
						teethWhiteningReviewSection?.subHeading ||
						"WHAT PEOPLE SAY ABOUT US"
					}
					page="Teeth whitening"
				/>
				{teethWhiteningTreatmentSection &&
					!checkPropertiesForNull(teethWhiteningTreatmentSection, [
						"heading",
					]) && (
						<section className="pt-8 pb-md-6 pb-5">
							<Container>
								<Row>
									<Col className="text-center">
										<p className="text-accent jost-bold fs-6">
											{teethWhiteningTreatmentSection?.subHeading}
										</p>
										<h2 className="text-text-secondary display-4 pb-4">
											{teethWhiteningTreatmentSection?.heading}
										</h2>
									</Col>
								</Row>
								{teethWhiteningTreatmentSection.items &&
									teethWhiteningTreatmentSection.items.length > 0 && (
										<Row className="pt-md-8 g-3 pt-5 h-100 justify-content-center">
											{" "}
											{teethWhiteningTreatmentSection.items.map((item) => (
												<Col
													style={{ minHeight: "100%" }}
													md={6}
													xl={4}
													key={uuid4()}
												>
													<div
														style={{
															boxShadow: "0px 3px 50px #00000029",
														}}
														className="bg-primary h-100 px-4 py-5 feature-box"
													>
														<h3 className="fs-3 text-center text-white pb-3 ">
															{item?.heading}
														</h3>
														<div className="content-white">
															<SafeHtmlParser htmlContent={item?.content} />
														</div>
														{item?.image && (
															<div className="text-center pt-4 d-flex align-items-center justify-content-center w-100">
																<GatsbyImage
																	className="mt-3  w-90"
																	image={
																		item?.image.node?.localFile.childImageSharp
																			.gatsbyImageData
																	}
																	alt={item?.image.node?.altText}
																/>
															</div>
														)}
													</div>
												</Col>
											))}
										</Row>
									)}
								{teethWhiteningTreatmentSection &&
									teethWhiteningTreatmentSection.cta && (
										<Row>
											<Col className="text-center mt-5 mt-md-6">
												{" "}
												<Button
													className="text-white py-3 px-5 w-100 w-md-auto"
													variant="accent"
													href={teethWhiteningTreatmentSection.cta?.url}
													target={
														teethWhiteningTreatmentSection.cta?.target ||
														"_blank"
													}
													rel="noreferrer"
												>
													{teethWhiteningTreatmentSection.cta?.title}
												</Button>
											</Col>
										</Row>
									)}
							</Container>
						</section>
					)}
				{teethWhiteningTextSection &&
					!checkPropertiesForNull(teethWhiteningTextSection, ["heading"]) && (
						<section className="bg-primary py-5 py-lg-7">
							<Container>
								<Row>
									<Col className="text-center">
										<h2 className=" text-white fs-1 mb-5 ">
											{teethWhiteningTextSection?.heading}
										</h2>
										<div className="content-white">
											<SafeHtmlParser
												htmlContent={teethWhiteningTextSection?.content}
											/>
										</div>
									</Col>
								</Row>
							</Container>
						</section>
					)}

				{teethWhiteningFaqSection &&
					!checkPropertiesForNull(teethWhiteningFaqSection, ["faqs"]) && (
						<Faq
							heading={teethWhiteningFaqSection?.heading}
							faqs={teethWhiteningFaqSection?.faqs.nodes}
						/>
					)}
			</Layout>
		</>
	);
};

export default TeethWhiteningPage;
